import { useCallback } from "react"
import LogoLinze from "./assets/images/logo_linze.png"
import MetodologiaLinze from "./assets/images/metodologiaLinze.png"
import MetodologiaLinzeMobile from "./assets/images/metodologiaLinzeMobile.png"
import CSF from './assets/images/csf_capa.png'
import IBL from './assets/images/ibl.png'
import ZapCerto from './assets/images/zapcerto.webp'
import BEJ from './assets/images/bej.jpeg'
import TorresERamon from './assets/images/torresERamon.png'
import G2 from './assets/images/g2.png'
import * as S from "./styles/global"

const SiteApp = () => {

    const handleWhatsApp = useCallback(() => {
        window.open('https://api.whatsapp.com/send?phone=5515998301761&text=Ol%C3%A1%20gostaria%20de%20agendar%20uma%20reuni%C3%A3o%20online%20para%20falar%20sobre%20meu%20neg%C3%B3cio')
    }, [])

  
    return (
        <S.Layout>
        <S.GlobalStyle />
        <S.Header>
            <S.Container>
             <img src={LogoLinze} alt="Linze" />
             <S.Menu>
                <S.MenuItem href="#services">O que fazemos</S.MenuItem>
                <S.MenuItem href="#about">Sobre nós</S.MenuItem>
                <S.MenuItem href="#clients">Clientes</S.MenuItem>
                <S.MenuItem href="#contact">Fale conosco</S.MenuItem>
             </S.Menu>
            </S.Container>
        </S.Header>
        <S.SafeSare>
            <S.Container>
                <S.SuperHeadline>Somos uma consultoria especializada em gestão de projetos com foco em transformação digital</S.SuperHeadline>
                <S.Headline>Ajudamos as empresas a construir todos os aspectos de um projeto digital de software, desde os objetivos estratégicos até os detalhes de experiência de seus clientes.</S.Headline>
                <S.Button onClick={handleWhatsApp}>Agende uma reunião</S.Button>
            </S.Container>
        </S.SafeSare>
        <S.Service id="services">
            <S.Container>
                <S.Headline>
                    Transformação digital
                </S.Headline>
                <S.Paragraphy>
                    Somos uma extensão do seu negócio, aceleramos a jornada digital da sua empresa para otimizar processos visando comunicação, integração e decisões baseadas em dados, obtendo mais eficiência e agilidade projetando novas experiências digitais que conectam as reais necessidades dos seus clientes com os objetivos estratégicos da sua empresa.
                </S.Paragraphy>
                <S.CardServiceContainer>
                    <S.CardService>
                        <S.Headline>Digitalização de serviços</S.Headline>
                        <S.Paragraphy>
                            Digitalize os processos operacionais da sua empresa potencializando a produtividade e efiência de seus serviços ou produtos e gere mais escalabilidade para seu negócio.
                        </S.Paragraphy>
                    </S.CardService>
                    <S.CardService>
                        <S.Headline>UX Design</S.Headline>
                        <S.Paragraphy>
                            Proporcione uma experiência memorável para seus clientes, mapeando as motivações e problemas, conectando os objetivos estratégicos do negócio a real necessidade dos clientes.
                        </S.Paragraphy>
                    </S.CardService>
                    <S.CardService>
                        <S.Headline>Software sob demanda</S.Headline>
                        <S.Paragraphy>
                            Diminua o custo, aumente a receita ou integre os procesos da sua empresa com a construção de software sob demanda desde de sites, sistemas ou aplicativos Android e iOS.
                        </S.Paragraphy>
                    </S.CardService>
                </S.CardServiceContainer>
            </S.Container>
        </S.Service>

        <S.serviceDetails>
            <S.Container>
                <S.Headline>Metodologia Linze</S.Headline>
                <picture>
                    <source  srcSet={MetodologiaLinzeMobile} media="(max-width: 768px)" />
                <S.MethodologyLinze src={MetodologiaLinze} alt="metodologia Linze" />
                </picture>
            </S.Container>
        </S.serviceDetails>

        <S.About id="about">
            <S.Container>
                <S.AboutDetails>
                    <S.Headline>Quem somos</S.Headline>
                    <S.Paragraphy>
                        A Linze é uma empresa que presta consultoria especializada em gestão de projetos digitais com foco em transformação digital, ajudando empresas e orgãos do governo a desenvolver métricas e escalabilidade através do ecossistema digital.
                    </S.Paragraphy>
                    <S.Headline>Missão</S.Headline>
                    <S.Paragraphy>
                        Ajudar empresas através da transformação digital, escalando seus produtos e serviços, colocando o cliente no centro do seu negócio.
                    </S.Paragraphy>
                    <S.Headline>Visão</S.Headline>
                    <S.Paragraphy>
                        Desenvolver boas experiências para pessoas, evoluindo produtos e serviços digitais, públicos e privados, no Brasil e no mundo.
                    </S.Paragraphy>
                </S.AboutDetails>
                <S.AboutValues>
                    <S.Headline>Valores</S.Headline>
                    <S.Paragraphy>
                        <strong>Pessoas no centro —</strong> As pessoas clientes são o centro da atenção e do negócio, e a experiência do serviço ou produto digital é trabalhada com essa premissa.
                    </S.Paragraphy>
                    <S.Paragraphy>
                        <strong>Transparência — </strong>A transparência é um valor que está presente durante todo o processo do serviço, nas relações com as empresas clientes e entre as pessoas do time.
                    </S.Paragraphy>
                    <S.Paragraphy>
                        <strong>Horizontalidade —</strong> As decisões e abordagens do time são baseadas em consenso. A opinião e a experiência das pessoas é ouvida e bem vinda.
                    </S.Paragraphy>
                    <S.Paragraphy>
                        <strong>Processo humanizado —</strong> A Linze tem uma visão humana das relações, que também emprega nas etapas do serviço, além de diversidade pessoal e profissional.
                    </S.Paragraphy>
                    <S.Paragraphy>
                        <strong>Desenvolver talentos —</strong> Um dos objetivos é o desenvolvimento interno de habilidades e interesses das pessoas da equipe.
                    </S.Paragraphy>
                </S.AboutValues>
            </S.Container>
        </S.About>

        <S.Clients id="clients">
            <S.Container>
                <S.Headline>Nossos clientes e parceiros</S.Headline>
                <S.CardServiceContainer>
                    <S.CardService>
                        <img src={CSF} alt="Cliente Conservation strategy found" />
                        <S.Paragraphy>Conservation Strategy Found</S.Paragraphy>
                    </S.CardService>
                    <S.CardService>
                        <img src={IBL} alt="Cliente IBL NET" />
                        <S.Paragraphy>IBL NET</S.Paragraphy>
                    </S.CardService>
                    <S.CardService>
                        <img src={ZapCerto} alt="Cliente ZapCerto" />
                        <S.Paragraphy>ZapCerto</S.Paragraphy>
                    </S.CardService>
                    <S.CardService>
                        <img src={BEJ} alt="Parceiro B&J Assessoria Pública e comercial" />
                        <S.Paragraphy>B&J Assessoria Pública e Comercial</S.Paragraphy>
                    </S.CardService>
                    <S.CardService>
                        <img src={TorresERamon} style={{filter: 'invert(1)', marginBottom: '70px'}} alt="Parceiro B&J Assessoria Pública e comercial" />
                        <S.Paragraphy>Torres & Ramon Advocacia</S.Paragraphy>
                    </S.CardService>
                    <S.CardService>
                        <img src={G2} alt="Parceiro G2 Corporation" />
                        <S.Paragraphy>G2 Corporation</S.Paragraphy>
                    </S.CardService>
                </S.CardServiceContainer>
            </S.Container>
        </S.Clients>
        
        <S.Contact id="contact">
            <S.Container>
                <div>
                <S.Headline>Fale conosco</S.Headline>
                <S.Paragraphy>WhatsApp / Telefone (15) 99830-1761</S.Paragraphy>
                <S.Paragraphy>Email para contato contato@linze.com.br</S.Paragraphy>
                </div>
                <S.Button onClick={handleWhatsApp}>Agende uma reunião</S.Button>
            </S.Container>
        </S.Contact>
        
        <S.Footer>
            <S.Paragraphy>2022 - 2023 Linze User Experience LTDA | CNPJ: 47.153.732/0001-85</S.Paragraphy>
        </S.Footer>
        </S.Layout>
    )
}

export default SiteApp