import styled, { createGlobalStyle } from 'styled-components'

const breakpoints = {
    md: '768px',
    lg: '1280px',
    xl: '1366px',
    xxl: '1440px',
    xxxl: '1600px',
    xxxxl: '1920px'
}

export const GlobalStyle = createGlobalStyle`
    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        font-family: 'Open Sans', sans-serif;
        background-color: #000;
        color: #fff;
    }
    h1,h2,h3,h4, p {
        line-height: 150%;
    }
    
    img {
        max-width: 100%;
    }
`

export const Button = styled.button`
    borDer: 0;
    width: 100%;
    height: 100px;
    font-size: 25px;
    font-weight: bold;
    background-color: #62B6B7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > img {
        margin-right: 16px;
    }
    @media (min-width: ${breakpoints.lg}) {
        width: 500px
    }
`

export const Container = styled.div`
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
    @media (min-width: ${breakpoints.lg}) {
        width: 1248px;
    }
    @media (min-width: ${breakpoints.xl}) {
        width: 1334px;
    }
    @media (min-width: ${breakpoints.xxl}) {
        width: 1380px;
    }
    @media (min-width: ${breakpoints.xxxl}) {
        width: 1568px;
    }
    @media (min-width: ${breakpoints.xxxxl}) {
        width: 1888px;
    }
`

export const Layout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
        'header header'
        'safeArea safeArea'
        'services services'
        'servicesDetails servicesDetails'
        'about about'
        'clients clients'
        'contact contact'
        'footer footer';

    @media (max-width: ${breakpoints.md}) {
        grid-template-columns: 1fr;
    }
`

export const SuperHeadline = styled.h1`
    font-size: 60px;
    @media (max-width: ${breakpoints.md}) {
        font-size: 45px;
    }
`

export const Headline = styled.h2`
    font-size: 40px;
    margin-bottom: 20px;
    @media (max-width: ${breakpoints.md}) {
        font-size: 30px;
    }
`

export const Paragraphy = styled.p`
    font-size: 30px;
    margin-bottom: 20px;
    @media (max-width: ${breakpoints.md}) {
        font-size: 29px;
    }
`

export const Header = styled.header`
    width: 100%;
    height: 80px;
    background: #222;
    grid-area: header;
    > div {
        height: 80px;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        @media (max-width: ${breakpoints.md}) {
            justify-content: center;
        }
    }
`

export const Menu = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: ${breakpoints.md}) {
        display: none;
    }
`

export const MenuItem = styled.a`
    border: 0;
    padding: 10px 0;
    border-bottom: 2px solid transparent;
    background: transparent;
    font-size: 20px;
    color: #fff;
    margin-right: 32px;
    cursor: pointer;
    &:hover {
        border-bottom: 2px solid #fff;
    }
`

export const SafeSare = styled.div`
    min-height: 100vh;
    background: #000;
    grid-area: safeArea;
    margin-top: 100px;
    h1 {
        width: 1200px;
        margin-bottom: 50px;
    }
    h2 {
        width: 1200px;
        font-size: 35px;
        font-weight: normal;
        margin-bottom: 50px;
    }
    @media (max-width: ${breakpoints.md}) {
        margin-top: 50px;
        h1, h2 {
            width: auto;
        }
    }
`

export const Service = styled.div`
    min-height: 100vh;
    background: #222;
    grid-area: services;
    padding: 100px 0;

`

export const CardServiceContainer = styled.div`
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 100px;
    @media (max-width: ${breakpoints.md}) {
        grid-template-columns: 1fr;
        gap: 50px;
    }
`

export const CardService = styled.div`
    min-height: 430px;
    background: #fff;
    text-align: center;
    padding: 25px 20px;
    color: #222;
    @media (max-width: ${breakpoints.md}) {
        height: auto;
    }
`

export const serviceDetails = styled.div`
    min-height: 100vh;
    background: #fff;
    grid-area: servicesDetails;
    padding: 100px 0;
    h2 {
        color: #222;
        text-align: center;
    }


`

export const Clients = styled.div`
    min-height: 100vh;
    background: #fff;
    grid-area: clients;
    padding: 100px 0;
    h2 {
        color: #222;
        text-align: center;
    }
    img {
        width: 150px;
        padding: 50px 0;
    }
`

export const About = styled.div`
    min-height: 100vh;
    background: #000;
    grid-area: about;
    padding: 100px 0;
    > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'aboutDetails aboutValues';
        gap: 100px;
    }

    @media (max-width: ${breakpoints.md}) {
        > div {
            grid-template-columns: 1fr;
            grid-template-areas:
            'aboutDetails'
            'aboutValues';
            gap: 50px;
        }
    }

    
    
`

export const AboutDetails = styled.div`
    grid-area: aboutDetails;
`

export const AboutValues = styled.div`
    grid-area: aboutValues;
`

export const Contact = styled.div`
    min-height: 400px;
    padding: 100px 0;
    background: #222;
    grid-area: contact;
    > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    @media (max-width: ${breakpoints.md}) {
        > div {
            grid-template-columns: 1fr;
        }
    }
`
export const Footer = styled.div`
    min-height: 100px;
    background: black;
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const MethodologyLinze = styled.img`
    margin: 0 auto;
    display: block;
`
